import * as React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import TextWrapper from "../components/Layouting/TextWrapper";
import Title from "../components/Title";
import Text from "../components/Text";

interface FooterSeitenProps {
  data: {
    cms: {
      entry: {
        title: string;
        text: string;
        beschreibung: string;
      };
    };
  };
}

export const FooterSeitenQuery = graphql`
  query ($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        ... on CMS_data_footerSeite_Entry {
          text
          beschreibung
        }
      }
    }
  }
`;

const FooterSeite: React.FC<FooterSeitenProps> = ({ data }) => (
  <DefaultLayout
    beschreibung={data.cms.entry.beschreibung}
    titel={data.cms.entry.title}
  >
    <Title text={data.cms.entry.title} topOffset />
    <TextWrapper>
      <Text text={data.cms.entry.text} />
    </TextWrapper>
  </DefaultLayout>
);

export default FooterSeite;
